import React, { useEffect, useState } from 'react';
import './App.css';
import RandomItemSpinner from './scripts/components/randomItemSpinner';

const App = () => {
    const [isReady, setIsReady] = useState(false);
    const [assets, setAssets] = useState([]);
    const [isSpinning, setIsSpinning] = useState(false);

    const loadImages = async (asset, type) => {
        const img = new URL(`/assets/img/${asset.filePath}`, window.location.origin);
        return {
            name: asset.name,
            type: type,
            img: img.href
        };
    };

    const shuffle = (array) => {
        let currentIndex = array.length;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {
            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
    }

    const loadAllAssets = async () => {
        const response = await fetch('/assets/assets.json');
        const assetData = await response.json();
        const allAssets = [];
        for (const assetType of ['blocks', 'items']) {
            const assetPaths = assetData[assetType];
            if (Array.isArray(assetPaths)) {
                // Shuffle the array
                shuffle(assetPaths);
                // Select the first 10 items
                const selectedAssets = assetPaths.slice(0, 10);
                for (const asset of selectedAssets) {
                    const image = await loadImages(asset, assetType.slice(0, -1));
                    allAssets.push(image);
                }
            } else {
                console.error(`Unexpected structure for ${assetType}:`, assetPaths);
            }
        }
        // Shuffle the array
        shuffle(allAssets);
        setAssets(allAssets);
        setIsReady(true);
    };

    useEffect(() => {
        loadAllAssets();
    }, []);

    const onClick = () => {
        setIsSpinning(true);
    };

    const onDone = () => {
        setIsSpinning(false);
        loadAllAssets();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {isReady && (
                <div className='widget' onClick={!isSpinning ? onClick : undefined}>
                    <RandomItemSpinner items={assets} isSpinning={isSpinning} onDone={onDone} />
                </div>
            )}
        </div>
    );
};

export default App;