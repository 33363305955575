import React, { useState, useEffect, useCallback, useRef } from 'react';

const RandomItemSpinner = ({ items, onDone, isSpinning, initialDelay = 10, rollTime = 5000 }) => {
    const [randomItem, setRandomItem] = useState(items[0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const delayRef = useRef(initialDelay); // Use a ref for delay

    useEffect(() => {
        if (!isSpinning) {
            return; // Don't start spinning until button is clicked
        }

        const startTime = Date.now();
        let index = currentIndex;

        const tick = () => {
            new Audio('assets/audio/click.mp3').play(); // Create a new Audio object and play it
            setRandomItem(items[index]);
            index = (index + 1) % items.length;
            delayRef.current *= 1.1; // Increase delay by 10%

            if (Date.now() - startTime > rollTime) {
                // Reset delay to initial value
                delayRef.current = initialDelay;
                onDone(); // Call the onDone callback when spinning is done
                return;
            } else {
                setTimeout(tick, delayRef.current); // Use delay from ref
            }
        };

        tick(); // Start the tick

        return () => clearTimeout(tick);
    }, [currentIndex, rollTime, initialDelay, isSpinning, onDone]);

    return (
        <div>
            {randomItem && ( 
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img className={`spinner-image-${randomItem.type}`} src={randomItem.img} alt={randomItem.name} />
                    </div>
                    <p style={{ marginTop: '200px', textAlign: 'center' }}>{randomItem.name}</p>
                </div>
            )}
        </div>
    );
};

export default RandomItemSpinner;